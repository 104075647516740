import React from "react";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import InfoCircle from '../../../assets/images/icons/info-circle.svg';
import { useFormikContext } from "formik";
import { useLayoutV2 } from "../../../context/LayoutProvider";

const WIFormInputTextV2 = (props: any) => {
  const { name, type = "text", title, isRequired, disabled, maxLength, onKeyDown, onChange, hideErrorWhenLoading } = props;
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext();
  const { progress } = useLayoutV2();

  return (
    <div className="element-form">
      <label className="label pb-16">
        {title}{" "}
        {isRequired ? <span className="required-label ml-4">*</span> : <></>}
        <div className="icon-info ml-16">
          <img src={InfoCircle} alt="Info" loading="lazy" className="m-0" />
        </div>
      </label>
      <InputText
        className={`${_.get(errors, name) && _.get(touched, name) ? "has-error" : ""}`}
        type={type}
        name={name}
        onChange={onChange || handleChange}
        onBlur={handleBlur}
        value={_.get(values, name) || ''}
        maxLength={maxLength}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
      {
        progress.loading && hideErrorWhenLoading ? <></> :
        <div className={`${_.get(errors, name) && _.get(touched, name) ? "error" : ""}`}>
          {_.get(touched, name) && _.get(errors, name)}
        </div>
      } 
    </div>
  )
}

export default WIFormInputTextV2;