import * as React from "react"
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthManagerAPI from '../../services/authManager';
import LogoLogo from '../../assets/images/login-logo.svg';
import { ReactComponent as CheckCircleSvg } from '../../assets/images/icons/check-circle.svg';
import './login.scss';
import { decodeBase64 } from "../../utils/logic";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { env } from "../../environment";
import { HOMEPAGE_URL, VALIDATIONS } from "../../components_v2/utils/utils";

const ConfirmNewPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validations] = useState(_.cloneDeep(VALIDATIONS));
  const [bgLoginIndex] = useState(Math.floor(Math.random() * 12));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t: errTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { t } = useTranslation('language', { keyPrefix: 'login_process' });

  const confirmNewPassword = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (!password || password.trim() !== confirmPassword?.trim()) {
      setError('txt_login_not_same');
      return;
    }
    setError('');
    try {
      const signInChallengeName = localStorage.getItem('signInChallengeName');
      const signInChallengeSession = localStorage.getItem('signInChallengeSession');
      const signInUsername = localStorage.getItem('signInUsername');
      const signInPassword = localStorage.getItem('signInPassword');

      if (!signInChallengeName || !signInChallengeSession) {
        throw new Error('Invalid Challenge Info');
      }

      if (!signInUsername || !signInPassword) {
        throw new Error('Incorrect Username or Password');
      }
      const signInChallengeResponses = { "NEW_PASSWORD": confirmPassword };
      let acceptChallengeResponse = await AuthManagerAPI.acceptChallenge(
        signInUsername,
        signInChallengeName,
        signInChallengeResponses,
        signInChallengeSession
      );
      if (acceptChallengeResponse && acceptChallengeResponse.status === 200) {
        const result = acceptChallengeResponse.data.result;
        // Update Local Storage with User's ID / Access / Refresh Tokens
        localStorage.setItem("id_token", result?.AuthenticationResult?.IdToken);
        localStorage.setItem("access_token", result?.AuthenticationResult?.AccessToken);
        localStorage.setItem("refresh_token", result?.AuthenticationResult?.RefreshToken);
        // Remove Challenge Info from Local Storage
        localStorage.removeItem('signInChallengeName');
        localStorage.removeItem('signInChallengeSession');
        localStorage.removeItem('signInUsername');
        localStorage.removeItem('signInPassword');
        const redirectUrl = searchParams?.get('state') ? decodeBase64(searchParams?.get('state') || '') : '';
        navigate(redirectUrl || HOMEPAGE_URL, { replace: true });
      } else {
        throw new Error('Cannot Accept Challenge');
      }
    } catch (error) {
      setError('txt_confirm_new_password_401');
    } finally {
      setIsLoading(false);
    }
  };

  const onKeyDown = (event: any): void => {
    if (event.code === 'Space') {
      event.preventDefault();
    }
  }

  useEffect(() => {
    // If no session, redirect to login page
    if (!localStorage.getItem('signInChallengeSession')?.trim()) {
      navigate(`/login${searchParams?.get('state') ? `?state=${searchParams?.get('state')}` : ''}`, { replace: true });
    }
  }, []);

  return (
    <div className="login-container">
      <img src={`${env.PUBLIC_IMAGE_URL}/bg_login${bgLoginIndex}.jpg`} alt="" className="background-cover" />
      <div className="background-backdrop"></div>
      <div className="login-form-wrapper">
        <img src={LogoLogo} alt="" className="login-logo" />
        <div className="login-form">
          <h2 className="login-title mb-24">{t('txt_title_confirm_new_password')}</h2>
          <form className="row-common" onSubmit={confirmNewPassword}>
            <div className="element-form mb-32">
              <label className="label mb-16">{t('txt_new_password')}</label>
              <InputText
                className="inputText"
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={isLoading}
                maxLength={40}
                onKeyDown={onKeyDown}
              />
            </div>
            <div className="element-form mb-16">
              <label className="label mb-16">{t('txt_confirm_new_password')}</label>
              <InputText
                className="inputText"
                id="password-2"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                disabled={isLoading}
                maxLength={40}
                onKeyDown={onKeyDown}
              />
            </div>
            <div className="label-text-parent mb-16">
              {
                validations.map((v: any) => {
                  return (
                    <div className={`label-text ${v.validate(password) ? 'validation-pass' : ''}`}>
                      <CheckCircleSvg></CheckCircleSvg>
                      <div className="no-space">{t(v.label)}</div>
                    </div>
                  );
                })
              }
            </div>
            {error ? <div className="login-error mb-16">{errTrans(error)}</div> : <></>}
            <Button
              className="wi-primary-button-v2 dots-button mt-16 h48 w-100"
              type={"submit"}
              label={t('txt_btn_confirm_new_password')}
              disabled={isLoading || !password || password !== confirmPassword || validations.some(c => !c.validate(password))}
            ></Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ConfirmNewPasswordPage;