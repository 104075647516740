// DEV ENVIRONMENT: Uncomment to deploy 
const DRUPAL_URL = process.env.DRUPAL_URL || 'https://portal.wilderness-international.dev-eagles.com';
const ADMIN_URL =  process.env.ADMIN_URL || 'https://admin.wilderness-international.dev-eagles.com';
const SIGNATURE =  process.env.SIGNATURE || '23Live02Slow86-TEST';
const AUTH_KEY =  process.env.AUTH_KEY || '2ZMQ1mGBzpdHjSv0XKi2KxD8JLJx';
const DONATION_IMPORT_URL = process.env.DONATION_IMPORT_URL || 'https://stripe-webhook.wilderness-international.dev-eagles.com';
const CMS_URL = process.env.CMS_URL || 'https://wilderness-international.dev-eagles.com';
const MEDIA_URL = process.env.MEDIA_URL || 'https://media.wilderness-international.dev-eagles.com';
const AWS_CLOUDFRONT_DISTRIBUTION_DOMAIN = process.env.AWS_CLOUDFRONT_DISTRIBUTION_DOMAIN || 'https://d3ikc68ccylu0h.cloudfront.net';
const CTA_LIBRARY_URL = `${AWS_CLOUDFRONT_DISTRIBUTION_DOMAIN}/cms`;
const DONATION_URL =  process.env.DONATION_URL || 'https://donate.wilderness-international.dev-eagles.com/';
const STRIPE_URL = process.env.STRIPE_URL || 'https://dashboard.stripe.com/test/payments/';
const PAYAL_URL = process.env.PAYAL_URL || 'https://www.sandbox.paypal.com/activity/payment/';
const BASE_STRIPE_URL = process.env.STRIPE_URL || 'https://dashboard.stripe.com/test';
// const TINYMCE_API_KEY = 'n7uljqowueacugh4dp8bbnvxs76lap83q6zysofz2eb5qolm';
const TINYMCE_API_KEY = 'n7uljqowueacugh4dp8bbnvxs76lap83q6zysofz2eb5qolm';
const CLIENT_ID = '67s5ivtdrdf63mchad3dhpd71n';
const REDIRECT_LINK = ADMIN_URL;
const LOGIN_URL = `/login`;
// Localhost
// const REDIRECT_LINK = 'http://localhost:3000';

// PRODUCTION ENVIRONMENT: Uncomment to deploy 
// const DRUPAL_URL = process.env.DRUPAL_URL || 'http://portal.wilderness-international.org';
// const AWS_CLOUDFRONT_DISTRIBUTION_DOMAIN = process.env.AWS_CLOUDFRONT_DISTRIBUTION_DOMAIN || 'https://d2ijqnyf2ixq2j.cloudfront.net';
// const CTA_LIBRARY_URL =  `${AWS_CLOUDFRONT_DISTRIBUTION_DOMAIN}/cms`;
// const ADMIN_URL =  process.env.ADMIN_URL || 'https://admin.wilderness-international.org';
// const SIGNATURE =  process.env.SIGNATURE || '23Live02Slow86';
// const DONATION_IMPORT_URL =  process.env.DONATION_IMPORT_URL || 'https://stripe-webhook.wilderness-international.org';
// const CMS_URL = process.env.CMS_URL || 'https://wilderness-international.org';
// const MEDIA_URL = process.env.MEDIA_URL || 'https://media.wilderness-international.org';
// const DONATION_URL =  process.env.DONATION_URL || 'https://donate.wilderness-international.org/';
// const STRIPE_URL = process.env.STRIPE_URL || 'https://dashboard.stripe.com/payments/';
// const PAYAL_URL = process.env.PAYAL_URL || 'https://paypal.com/activity/payment/';
// const BASE_STRIPE_URL = process.env.STRIPE_URL || 'https://dashboard.stripe.com';
// const TINYMCE_API_KEY = 'inberbit8uky2dq8giuounqrxe6dykxpr6gey2p36gnysp39'; // Huu
// // const TINYMCE_API_KEY = 'kfwwuh6amyrgyfivaw0y5kbwpkxg29sn3xp2f0f9saqmflal'; // Dung
// const CLIENT_ID = '67s5ivtdrdf63mchad3dhpd71n';
// const REDIRECT_LINK = ADMIN_URL;
// const LOGIN_URL = `/login`;
// const AUTH_KEY =  process.env.AUTH_KEY || '2ZMQ1mGBzpdHjSv0XKi2KxD8JLJx';

export const env = {
    AXIOS_URL_DEV: 'http://localhost:4242',
    SIGNATURE: SIGNATURE,
    CMS_URL: CMS_URL,
    AXIOS_URL: DONATION_IMPORT_URL,
    DRUPAL_URL: DRUPAL_URL,
    // PUBLIC_IMAGE_URL: `${MEDIA_URL}/images`,
    PUBLIC_IMAGE_URL: `${AWS_CLOUDFRONT_DISTRIBUTION_DOMAIN}/static-images`,
    ADMIN_WOFF2_URL : `${ADMIN_URL}/fonts/icon/wildicon.woff2`,
    ADMIN_WOFF_URL : `${ADMIN_URL}/fonts/icon/wildicon.woff`,
    LIB_ABOVE_URL : `${CTA_LIBRARY_URL}/css/above_the_fold.min.css`,
    LIB_MAIN_URL : `${CTA_LIBRARY_URL}/css/main.min.css`,
    LIB_THEME_URL : `${CTA_LIBRARY_URL}/js/min/theme.min.js`,
    LIB_SLIDERS_URL : `${CTA_LIBRARY_URL}/js/min/sliders.min.js`,
    LIB_ACCORDION_URL : `${CTA_LIBRARY_URL}/js/min/accordion.min.js`,
    LIB_CUSTOM_SELECT_URL : `${CTA_LIBRARY_URL}/js/min/custom_select.min.js`,
    LIB_CUSTOM_NUMBER_URL : `${CTA_LIBRARY_URL}/js/min/custom_number_input.min.js`,
    LIB_DOUBLE_OPTION_URL : `${CTA_LIBRARY_URL}/js/min/double_optin.min.js`,
    LIB_LAZY_LOADING_URL : `${CTA_LIBRARY_URL}/js/min/lazy_loading.min.js`,
    LIB_MENU_URL : `${CTA_LIBRARY_URL}/js/min/menu.min.js`,
    LIB_NEW_LETTER_URL : `${CTA_LIBRARY_URL}/js/min/newsletter_signup.min.js`,
    LIB_OTP_OUT_URL : `${CTA_LIBRARY_URL}/js/min/opt_out.min.js`,
    LIB_RESUBSCRIBE_URL : `${CTA_LIBRARY_URL}/js/min/resubscribe.min.js`,
    LIB_STICKY_URL : `${CTA_LIBRARY_URL}/js/min/sticky_cta_btn.min.js`,
    LIB_404_MINIGAME_URL : `${CTA_LIBRARY_URL}/css/404_minigame.min.css`,
    LIB_MIN_MINIGAME_URL: `${CTA_LIBRARY_URL}/js/min/404-minigame.min.js`,
    LIB_SLIM_URL: `${CTA_LIBRARY_URL}/slim.min.js`,
    LIB_POPPER_URL: `${CTA_LIBRARY_URL}/popper.min.js`,
    LIB_BOOTSTRAP_JS_URL: `${CTA_LIBRARY_URL}/bootstrap.min.js`,
    REALTIME_JS_URL: `${CTA_LIBRARY_URL}/realtime-data.js`,
    LIB_BOOTSTRAP_URL: `${CTA_LIBRARY_URL}/bootstrap.min.css`,
    CTA_LIBRARY_URL: CTA_LIBRARY_URL,
    // PUBLIC_UPLOAD_URL: `${MEDIA_URL}/uploads`,
    PUBLIC_UPLOAD_URL: `${AWS_CLOUDFRONT_DISTRIBUTION_DOMAIN}/media`,
    PUBLIC_THUMBNAIL_URL: `${MEDIA_URL}/thumbnails`,
    EDITOR_API_KEY: TINYMCE_API_KEY,
    DONATION_URL : DONATION_URL,
    STRIPE_URL: STRIPE_URL,
    PAYAL_URL: PAYAL_URL,
    ADMIN_URL: ADMIN_URL,
    BASE_STRIPE_URL: BASE_STRIPE_URL,
    LOGIN_URL,
    REDIRECT_LINK: REDIRECT_LINK,
    CLIENT_ID,
    AUTH_KEY
}