import { env } from '../environment';
import { AxiosClient, cacheAge } from './httpClient';
import { CognitoIdentityProviderClient, InitiateAuthCommand, SignUpCommand, ConfirmSignUpCommand } from "@aws-sdk/client-cognito-identity-provider";
const config = {
  "region": "eu-central-1",
  "userPoolId": "eu-central-1_uIaiARUUt",
  "clientId": "67s5ivtdrdf63mchad3dhpd71n"
}

export default class AuthManagerAPI {

  /**
   * Sign User In
   * @param email: User's Email
   * @param password: User's Password
   * @returns ID / Access / Refresh tokens or Challenge (if any Auth Challenge (MFA or New Password Required) needed) 
   */
  static signIn(email: string, password: string): any {
    return AxiosClient.post(`/auth-v2-service/signin`,  {
      username: email,
      password
    });
  }

  /**
   * Accept Auth Challenge (for ex: provide New Password or MFA Code)
   * @param email: User's Email
   * @param challengeName: Auth Challenge Name
   * @param challengeResponses: Auth Challenge Responses
   * @param challengeSession: Auth Challenge Session
   * @returns ID / Access / Refresh tokens
   */
  static acceptChallenge(email: string, challengeName: String, challengeResponses: any, challengeSession: String | null): any {
    return AxiosClient.post(`/auth-v2-service/challenge/accept`,  {
      username: email,
      challengeName,
      challengeResponses,
      challengeSession
    });
  }

  /**
   * Set User Password (after logging-in on the first time)
   * @param email: User's Email
   * @param password: User's Password
   * @param isPermanent: TRUE if this is permanent Password
   * @returns Axios Response Object
   */
  static setUserPassword(email: string, password: string, isPermanent: boolean = true): any {
    return AxiosClient.post(`/auth-v2-service/user/password`,  {
      username: email,
      password,
      isPermanent
    });
  }

  static changeUserPassword(email: string, password: string, newPassword: string): any {
    return AxiosClient.post(`/auth-v2-service/user/change-password`,  {
      username: email,
      password,
      new_password: newPassword
    });
  }

  static forgotUserPassword(email: string): any {
    return AxiosClient.post(`/auth-v2-service/user/forgot-password`,  { email });
  }

  static getAccessToken(authorizationCode: string, redirectUri: string): any {
    return AxiosClient.post(`/auth-v2-service/token`,  {
      code: authorizationCode,
      clientId: env.CLIENT_ID,
      redirectUri: env.REDIRECT_LINK
    });
  }

  static refreshAccessToken(refreshToken: string): any {
    return AxiosClient.post(`/auth-v2-service/token/refresh`,  {
      refreshToken: refreshToken,
      clientId: env.CLIENT_ID,
    });
  }
}

export const cognitoClient = new CognitoIdentityProviderClient({
  region: config.region,
});

export const signIn = async (username: string, password: string) => {
  // const hasher = createHmac('sha256', "26s5ru1lvuav4fjr89rvetufr6da0c8b65m7c0jo4c94k0rsqpt");
  // // AWS wants `"Username" + "Client Id"`
  // hasher.update(`${username}${config.clientId}`);
  // const secretHash = hasher.digest('base64');

  const params: any = {
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: config.clientId,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
      // SECRET_HASH: secretHash
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const { AuthenticationResult } = await cognitoClient.send(command);
    if (AuthenticationResult) {
      sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
      sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
      sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');
      return AuthenticationResult;
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};